<template>
    <div id="rodape">
        <img id="rodape-logos" src="@/assets/img/rodape/logos.png">
        <h6>©2023 Purpose Campaigns PBC, Purpose Europe Limited</h6>
        <p><a href="https://purpose.com/?lang=pt-br" target="_blank">Acesse o site da Purpose</a> | <a @click="$router.push({ name: 'privacidade' });">Políticas de privacidade</a></p>
        <a @click="$emit('openForm')" id="mail">
            <img src="@/assets/img/rodape/mail.png">
        </a>
    </div>
</template>

<script>
export default {
    name: 'CsRodape',
    data() {
        return {
        }
    }
}
</script>

<style lang="less">
#rodape {
    .tc;
    padding: 15px 0 20px;

    h6 {
        .cmarrom;
        .m0;
        .w400;
    }

    p {
        .cmarrom;
        .tc;
        .t10;
        .m0;

        a {
            text-decoration: underline !important;
        }
    }

    #rodape-logos {
        display: none;
    }

    #mail {
        display: none;
    }

    @media(min-width: 1200px) {
        position: relative; z-index: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        h6{
            margin-right: 20px;
        }

        #rodape-logos {
            .ib;
            margin-right: 50px;
        }

        #mail {
            .ib;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 21px;
            margin-left: 20px;
        }
    }
}</style>