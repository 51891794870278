<template>
    <MenuMobile v-if="windowWidth < 1200"></MenuMobile>
    <MenuDesktop @openCompartilhar="openCompartilhar" v-else></MenuDesktop>
    <router-view name="Conteudo" v-slot="{ Component }">
        <Transition name="animation-2" mode="out-in">
            <keep-alive>
                <component :is="Component" />
            </keep-alive>
        </Transition>
    </router-view>
    <router-view  @openForm="openForm" name="CasaMobile" v-slot="{ Component }">
        <Transition name="animation-1" mode="out-in">
            <keep-alive>
                <component :is="Component" />
            </keep-alive>
        </Transition>
    </router-view>
    <router-view @openForm="openForm" name="InscrevaSe"></router-view>
    <router-view @openForm="openForm" name="Rodape"></router-view>
    <Transition name="animation-1">
        <Compartilhar @closeCompartilhar="closeCompartilhar" v-show="compartilhar"></Compartilhar>
    </Transition>
    <Transition name="animation-1">
        <EnvieMensagem @closeForm="closeForm" v-show="form"></EnvieMensagem>
    </Transition>
</template>

<script>
import MenuMobile from './components/partes/MenuMobile.vue';
import MenuDesktop from './components/partes/MenuDesktop.vue';
import Compartilhar from './components/partes/Compartilhar.vue';
import EnvieMensagem from './components/partes/EnvieMensagem.vue';

export default {
    name: 'App',
    components: { MenuMobile, MenuDesktop, Compartilhar, EnvieMensagem },
    data() {
        return {
            windowWidth: window.innerWidth,
            compartilhar: false,
            form: false,
        }
    },
    methods: {
        closeCompartilhar() {
            this.compartilhar = false;
        },
        openCompartilhar() {
            this.compartilhar = true;
        },
        closeForm() {
            this.form = false;
        },
        openForm() {
            this.form = true;
        }
    },
    mounted() {
        window.onresize = () => { this.windowWidth = window.innerWidth; }
        document.addEventListener('keyup', e => {
            if(e.code == 'Escape'){ this.compartilhar = false; this.form = false; }
        })
    }
}
</script>

<style lang="less">body {
    .bbege;
}

.conteudo {
    position: relative; z-index: 20;
    padding: 40px 15px 0;
    .transition; .tc;

    h3 {
        .cazul;
        .alterfont;
        .w400;
        .t18;
        .mb1;

        @media(min-width: 992px) {
            .t32;
        }
    }

    h4 {
        .cgrafite;
        .alterfont;
        .w400;
        .t32;
        .mb1;
    }

    svg,
    img {
        max-width: 100%;
    }
    &.varanda,&.interior,&.interior-interno,&.quintal{
        h2 img{
            width: 100%; max-width: 380px;
        }
    }

    p {
        .ccinzaescuro;
        .t16;
    }
}

hr {
    border: none;
    border-bottom: solid 3px transparent;
    border-image-slice: 3 0 3 0;
    border-image-width: 0px 0px 20px 0px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: stretch stretch;
    border-image-source: url('~@/assets/img/hr.svg');
}

.animation-1-enter-from {
    transform: translateY(100vh);
}

.animation-1-enter-active {
    transform: translateY(100vh);
}

.animation-1-enter-to {
    transform: translateY(0vh);
}

.animation-1-leave-from {
    transform: translateY(0vh);
}

.animation-1-leave-active {
    transform: translateY(0vh);
}

.animation-1-leave-to {
    transform: translateY(100vh);
}

.animation-2-enter-from {
    transform: translateX(100vw);
}

.animation-2-enter-active {
    transform: translateX(100vw);
}

.animation-2-enter-to {
    transform: translateX(0vw);
}

.animation-2-leave-from {
    transform: translateX(0vw);
}

.animation-2-leave-active {
    transform: translateX(0vw);
}

.animation-2-leave-to {
    transform: translateX(100vw);
}
</style>