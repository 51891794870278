import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import App from './App.vue'
const Home = () => import(/* webpackChunkName: "home" */ './components/Home.vue');
const CasaMobile = () => import(/* webpackChunkName: "casa-mobile" */ './components/CasaMobile.vue');

const Varanda = () => import(/* webpackChunkName: "varanda" */ './components/Casa/Varanda.vue');
const Interior = () => import(/* webpackChunkName: "interior" */ './components/Casa/Interior.vue');
const Quintal = () => import(/* webpackChunkName: "quintal" */ './components/Casa/Quintal.vue');

const QuemFaz = () => import(/* webpackChunkName: "quem-faz" */ './components/QuemFaz.vue');
const Sobre = () => import(/* webpackChunkName: "sobre" */ './components/Sobre.vue');
const ComoUtilizar = () => import(/* webpackChunkName: "como-utilizar" */ './components/ComoUtilizar.vue');
const Privacidade = () => import(/* webpackChunkName: "como-utilizar" */ './components/Privacidade.vue');
const Galeria = () => import(/* webpackChunkName: "como-utilizar" */ './components/Galeria.vue');

const InscrevaSe = () => import(/* webpackChunkName: "inscreva-se" */ './components/InscrevaSe.vue');
const Rodape = () => import(/* webpackChunkName: "rodape" */ './components/Rodape.vue');

const app = createApp(App);

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/', name: 'home',
            components: {
                Conteudo: Home, CasaMobile, Rodape
            },
            props: {
                CasaMobile: { houseState: 'inicio' }
            }
        },
        {
            path: '/casa', name: 'casa',
            children: [
                {
                    path: 'varanda', name: 'varanda',
                    components: {
                        Conteudo: Varanda, CasaMobile, Rodape
                    },
                    props: {
                        CasaMobile: { houseState: 'varanda' }
                    }
                },
                {
                    path: 'interior', name: 'interior',
                    components: {
                        Conteudo: Interior, CasaMobile, Rodape
                    },
                    props: {
                        CasaMobile: { houseState: 'interior' }
                    }
                },
                {
                    path: 'interior-interno', name: 'interior-interno',
                    components: {
                        Conteudo: Interior, CasaMobile, Rodape
                    },
                    props: {
                        CasaMobile: { houseState: 'interior-interno' }
                    }
                },
                {
                    path: 'quintal', name: 'quintal',
                    components: {
                        Conteudo: Quintal, CasaMobile, Rodape
                    },
                    props: {
                        CasaMobile: { houseState: 'quintal' }
                    }
                }
            ]
        },
        {
            path: '/quem-faz', name: 'quem-faz',
            components: {
                Conteudo: QuemFaz, InscrevaSe, Rodape
            }
        },
        {
            path: '/sobre', name: 'sobre',
            components: {
                Conteudo: Sobre, InscrevaSe, Rodape
            }
        },
        {
            path: '/como-utilizar', name: 'como-utilizar',
            components: {
                Conteudo: ComoUtilizar, InscrevaSe, Rodape
            }
        },
        {
            path: '/privacidade', name: 'privacidade',
            components: {
                Conteudo: Privacidade, InscrevaSe, Rodape
            }
        },
        {
            path: '/galeria', name: 'galeria',
            components: {
                Conteudo: Galeria, Rodape
            }
        },
    ],
})
app.use(router)

router.isReady().then(() => app.mount('#app'))
